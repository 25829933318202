<template>
  <div v-loading.fullscreen.lock="loading">
    <div class="header-section">
      <div class="container">
        <div class="row login">
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div class="col-md-8 login-inputs mb-3">
                <login-input></login-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Banner Section--->
    <section class="banner-section custmMob">
      <div class="container">
        <div class="banner-caption">
          <h2>Restaurant Employee Development Training</h2>
          <p>
            Equip Your Team with Essential Skills and Knowledge Through CRA's Restaurant Employee Development Training and Ensure Compliance with California’s Sexual Harassment Prevention Training Standards
          </p>
        </div>
      </div>
    </section>
    <!--Banner Section--->
    <!-- Page content -->
    <div class="content-area" style="padding-top:20px;">
      <div class="container-fluid">
        <h5 class="mb-5 text-center">
          If you are a current member of the CRA, you will receive special
          pricing by selecting the box next to the “Already a member of the
          CRA”. Enter your Member ID or name of your restaurant to receive your
          discount. After agreeing to the Service Activation Agreement and
          paying for the course(s), you will be sent a Welcome Email and can
          continue directly to the site to login. If you have any questions or
          don’t see a course you need, please contact us at
          <a :href="'mailto:' + infoEmail" class="linkColor">{{ infoEmail }}</a
          >.
        </h5>
        <div class="row">
          <div class="col-lg-5 col-md-6 col-sm-12 col-12 form-section custom-form responsive-order">
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator" class="custom-bg-mobil" >
              <form role="form" @submit.prevent="handleSubmit()" >
                <div class="row">
                  <div class="col-md-12">
                    <h4 style="color: #444c57" class="">Your Details</h4>
                    <hr />
                  </div>
                  <br />
                </div>

                <div class="form-row ">
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <label class="form-control-label">Company Name </label>
                    <base-input
                      type="text"
                      name="Company name"
                      placeholder="Company Name"
                      v-model="employee.company_name"
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <label class="form-control-label"
                      >First Name <span class="req"> *</span></label
                    >
                    <base-input
                      type="text"
                      name="First name"
                      placeholder="First Name"
                      rules="required|alpha"
                      v-model="employee.first_name"
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <label class="form-control-label"
                      >Last Name <span class="req"> *</span></label
                    >
                    <base-input
                      type="text"
                      name="Last name"
                      placeholder="Last Name"
                      rules="required|alpha"
                      v-model="employee.last_name"
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <label class="form-control-label"
                      >Email <span class="req"> *</span></label
                    >
                    <base-input
                      type="email"
                      name="Email"
                      placeholder="Email"
                      rules="required"
                      v-model="employee.email"
                    >
                    </base-input>
                  </div>

                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <label class="form-control-label"
                      >Phone <span class="req"> *</span></label
                    >
                    <base-input
                      name="Telephone"
                      placeholder="Phone"
                      rules="required"
                      v-model="employee.phone"
                      @input="acceptNumber"
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <label class="form-control-label"
                      >Address <span class="req"> *</span></label
                    >
                    <base-input
                      type="text"
                      name="Address"
                      placeholder="Address"
                      v-model="employee.address"
                    ></base-input>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <label class="form-control-label"
                      >City <span class="req"> *</span></label
                    >
                    <base-input
                      type="text"
                      name="City"
                      placeholder="City"
                      v-model="employee.city"
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12" >
                    <label class="form-control-label"
                      >State <span class="req"> *</span></label
                    >
                    <base-input
                      type="text"
                      name="State"
                      placeholder="State"
                      v-model="employee.state"
                    >
                    </base-input>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12 mb-3 input-wrapper" >
                    <label class="form-control-label"
                      >Zip Code <span class="req"> *</span></label
                    >
                    <base-input
                      type="number"
                      name="Zip code"
                      placeholder="Zip Code"
                      v-model="employee.zipcode"
                      rules="required|numeric|digits:5"
                      class=""
                      ref="zipcodeInput"
                      @input="handleZipcodeChange"
                      
                    >
                    </base-input>
                  </div>
                </div>
                <hr />
                <div class="row" v-if="lead_id">
                  <h3 style="color: #444c57" class="mt-2 ml-2">
                    Login Information
                  </h3>
                </div>
                <hr v-if="lead_id" />
                <div class="form-row">
                  <div class="col-md-6">
                    <label class="form-control-label"
                      >Username <span class="req"> *</span></label
                    >
                    <base-input
                      type="test"
                      name="Username"
                      placeholder="Username"
                      readonly
                      v-model="employee.email"
                      style="display: initial;"
                    >
                    </base-input>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label"
                      >Password <span class="req"> *</span></label
                    >
                    <base-input
                      :type="passwordFieldType"
                      name="Password"
                      placeholder="Password"
                      v-model="employee.password"
                    >
                    </base-input>
                    <div class="password-eye passwordview">
                      <span @click.prevent="switchVisibility"
                        ><i class="fa fa-eye" title="Show Password"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
          <div class="col-lg-7 col-md-6 col-sm-12 col-12 responsive-order ">
            <div class="row">
              <div class="col-12">
                <!-- member and course selection section -->
                <div class="row">
                  <div class="col-md-12 mt-0 mb-3 text-center bg-gray-100 p-2 shadow-sm">
                    <h5 class="mb-0 new-signup">
                      Not a member,
                      <a
                        class="linkColor"
                        href="https://www.calrest.org/membership"
                      >
                        click here
                      </a>
                      <span>to join.</span>
                    </h5>
                  </div>
                  <div class="col-md-12">
                    <base-checkbox v-model="already_member"
                      ><b
                        >Already a member of the CRA? (Enter your Member ID to
                        receive a discount).</b
                      ></base-checkbox
                    >
                  </div>

                  <div class="col-md-12 mt-1" v-if="already_member">
                    <!-- <base-input
                      label="Member ID:"
                      placeholder="Provide member ID"
                      :style="inputStyle"
                      v-model="member_id"
                    ></base-input> -->
                    <label class="form-control-label">Member ID:</label>
                    <input type="text" :style="inputStyle"  id="memberId" class="form-control" v-model="member_id" placeholder="Provide member ID"/>
                  </div>
                  <div class="col-md-12 mt-2">
                    <div class="row priceGrid  custom-bg" style="font-weight:bolder;">
                      <div class="col-lg-6 col-md-6 col-sm-8 col-8 price-info" >Courses</div>
                      <div class="col-lg-3 col-md-3 col-sm-2 col-2 price-info text-center">Member Pricing</div>
                      <div class="col-lg-3 col-md-3 col-sm-2 col-2 price-info text-center">Non-Member Pricing</div>
                    </div>
                      <el-checkbox-group v-model="checked_courses">
                      <span class="odd-row" v-for="option in course" :key="option.id">
                        <div class="row priceGrid">
                        <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                        <el-checkbox
                            :label="option.id"
                            v-if="option.course_type == 0"
                        >{{ option.course_name }}</el-checkbox
                        >
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                        <span
                            class="price-info"

                        >${{option.member_price}}</span
                        >
                        </div>
                          <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                        <span
                            class="price-info"

                        >${{option.non_member_price}}</span
                        >
                        </div>
                        </div>
                        <br />
                      </span>

                      </el-checkbox-group>
                    <el-checkbox-group v-model="checked_coursefolders">
                      <span class="odd-row"  v-for="option in coursefolders" :key="option.id">
                      <div class="row priceGrid">
                        <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                        <el-popover
                          :ref="`fromPopOver${option.id}`"
                          placement="top-start"
                          width="500"
                          trigger="hover"
                          :key="option.id"
                          :id="option.id"
                        >
                          <div class="provideScroller">
                            <ul>
                              <li
                                v-for="(courses, index) in option.attachedCourses"
                                :key="index"
                              >
                                {{ courses.name }}
                              </li>
                            </ul>
                          </div>
                        </el-popover>
                        <el-checkbox
                          @change="courseFolderChecked(option.id)"
                          :label="option.id"
                        >
                          <span>{{ option.coursefolder_name }}</span>
                        </el-checkbox>

                        <span
                          v-popover="`fromPopOver${option.id}`"
                          class="text-primary knowMore-info"
                          >Learn More</span
                        >
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                        <span
                          class="price-info "

                          >${{option.member_price}}</span
                        >
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                        <span
                          class="price-info"

                          >${{option.non_member_price}}</span
                        >
                        </div>
                        <br />
                      </div>
                      </span>
                    </el-checkbox-group>
    <!--
                    <el-checkbox-group v-model="checked_other_courses">
                      <span class="odd-row"  v-for="option in basicPackage1" :key="option.id">
                        <div class="row priceGrid">
                        <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                        <el-popover
                          :ref="`fromPopOver${option.id}`"
                          placement="top-start"
                          width="500"
                          trigger="hover"
                          :key="option.id"
                          :id="option.id"
                        >
                          <div class="provideScroller">
                            <ul>
                              <li
                                v-for="(courses, index) in option.attachedCourses"
                                :key="index"
                              >
                                {{ courses.name }}
                              </li>
                            </ul>
                          </div>
                        </el-popover>
                        <el-checkbox :label="option.id">{{
                          option.coursefolder_name
                        }}</el-checkbox
                        ><span
                          v-popover="`fromPopOver${option.id}`"
                          class="text-primary knowMore-info"
                          >Learn More</span
                        >
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                        <span
                          class="price-info"

                          >${{option.member_price}}</span
                        >
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                        <span
                          class="price-info"

                          >${{option.non_member_price}}</span
                        >
                        </div></div>
                      </span>

                    </el-checkbox-group>

                    <el-checkbox-group v-model="checked_other_courses">
                      <span class="odd-row" v-for="option in basicPackage2" :key="option.id">
                        <div class="row priceGrid">
                        <div class="col-lg-6 col-md-6 col-sm-8 col-8">
                        <el-popover
                          :ref="`fromPopOver${option.id}`"
                          placement="top-start"
                          width="500"
                          trigger="hover"
                          :key="option.id"
                          :id="option.id"
                        >
                          <div class="provideScroller">
                            <ul>
                              <li
                                v-for="(courses, index) in option.attachedCourses"
                                :key="index"
                              >
                                {{ courses.name }}
                              </li>
                            </ul>
                          </div>
                        </el-popover>
                        <el-checkbox
                          :label="option.id"
                          v-if="checked_coursefolders.length > 0"
                          disabled
                          >{{ option.coursefolder_name }}</el-checkbox
                        >
                        <el-checkbox
                          :label="option.id"
                          v-else-if="checked_coursefolders.length == 0"
                          >{{ option.coursefolder_name }}</el-checkbox
                        ><span
                          v-popover="`fromPopOver${option.id}`"
                          class="text-primary knowMore-info"
                          >Learn More</span
                        >
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                        <span
                          class="price-info"

                          >${{option.member_price}}</span
                        >
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-2 col-2 text-center">
                        <span
                          class="price-info"

                          >${{option.non_member_price}}</span
                        >
                        </div></div>
                        <br />
                      </span>
                    </el-checkbox-group>-->

                  </div>
                  <br />
                </div>
                <!-- end member and course selection section-->
              </div>
              <div class="col-12 my-5">
                <!-- promo code elements -->
                <div class="text-center" v-if="!lead_id">
                    <base-button class="" 
                      @click.prevent="submitLead"
                      :disabled="!(checked_courses.length || checked_coursefolders.length)"
                    >
                    Calculate Price
                    </base-button
                    >
                </div>
                <div v-if="showPricePlan && lead_id" class="price-area">
                    <hr/>

                    <div class="row text-center">
                        <div class="col-12" style="color: darkblue" v-if="!promoCodeApplied">
                              <h3><span class="mr-3">Total Amount:</span> {{ formatPrice(sub_total) }}</h3>
                        </div>
                        <div class="col-12 mt-4" v-else>
                            <div class="row payable-content">
                                <div class="col-md-12 col-12 mb-4">
                                    <span class="promocode-applied">Promotional Discount Applied ({{ discount_percentage }}%)</span>
                                </div>
                                <div class="col-md-6 col-6">
                                    <label class="form-control-label">Course Cost:</label>
                                </div>
                                <div class="col-md-6 col-6">
                                    <label class="form-control-label">{{ formatPrice(course_cost) }}</label>
                                </div>
                            </div>
                            <div class="row payable-content">
                                <div class="col-md-6 col-6">
                                    <label class="form-control-label">Minus Discount:</label>
                                </div>
                                <div class="col-md-6 col-6">
                                    <label class="form-control-label">-{{ formatPrice(discounted_cost) }}</label>
                                </div>
                            </div>
                            <div class="row payable-content">
                                <div class="col-md-6 col-6">
                                    <label class="form-control-label" style="text-decoration:underline; font-weight:bold;">Total Amount Due:</label>
                                </div>
                                <div class="col-md-6 col-6">
                                    <label class="form-control-label" style="text-decoration:underline; font-weight:bold;">{{ formatPrice(sub_total) }}</label>
                                </div>
                            </div>
                            <span class="promocode-applied pt-4">{{ appliedText }}</span>
                        </div>
                    </div>
                    <div class="row mt-4 justify-content-center align-items-center" v-if="showPromoCodeOption">
                        <div class="col-8 col-md-12 col-lg-8 col-xl-6">
                          <div class="row" v-if="showPromoCodeOption">
                              <div class="col-md-12">
                                  <label class="form-control-label">Promotional Code: </label>
                              </div>
                              <div class="col-8 ">
                                  <base-input
                                      placeholder="Enter Promotional Code Here" rules="required" name="Promotional Code" v-model="promo_code"></base-input>
                              </div>
                              <div class="col-4 p-0 m-0 ">
                                  <base-button size="md" type="success" @click.prevent="applyPromoCode">Apply Code</base-button>
                              </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div v-if="lead_id" class="mb-2 text-center">
                        <base-button type="danger" @click.prevent="reestimate"
                        >Re-estimate
                        </base-button
                        >
                        <base-button class="custom-btn" @click.prevent="showAgreement()" :disabled="isContinueButtonDisabled"
                        >Continue
                        </base-button
                        >
                    </div>
                </div>
                <!-- end promo code elements -->
              </div>
            </div>
            
          </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6">
              
            </div>
            <!-- <div class="col-md-12">
                 <div
              class="text-center py-3"
              v-if="
                (checked_courses.length ||
                  checked_coursefolders.length ||
                  checked_other_courses.length) &&
                  !lead_id
              "
            >
              <base-button class="mt-2" @click.prevent="checkIfCRAMember"
                >Sign Up</base-button
              >
            </div>
            <div class="text-center py-3" v-else>
              <base-button
                v-if="!showPricePlan"
                class="basebutton mt-2"
                disabled
                >Sign Up</base-button
              >
            </div>
            </div> -->
        </div>
      </div>
    </div>
    <modal :show.sync="agreementModal" class="user-modal">
      <h3 slot="header">Terms and Conditions</h3>
      <form>
        <div class="agreement-content">
          <agreement type="individual"></agreement>
        </div>
        <div class="text-center mt-2">
          <base-button type="success" @click.prevent="finalCreateAccount">
            I Agree
          </base-button>
          <base-button type="danger" @click.prevent="cancelAgreement">
            Cancel
          </base-button>
        </div>
      </form>
    </modal>
    <modal :show.sync="showPaymentOption">
      <h4 slot="header" style="color: #444c57" class="modal-title mb-0">
        Payment
      </h4>

      <credit-card
        v-if="showPaymentOption"
        type="individual"
        :monthlyAmount="sub_total"
        :yearlyAmount="perYearCost"
        :onlySexualCourse="0"
        :city="employee.city"
        :state="employee.state"
        :address="employee.address"
        :zip="employee.zipcode"
        :enablePaymentButton="enablePaymentButton"
        v-on:payClicked="payClicked"
      />
    </modal>

    <modal :show.sync="paymentModalMessage">
          <h1 class="text-capitalize text-center text-strong m-0 payment-modal-text text-danger font-weight-500">Your payment is processing...</h1>
      </modal>

  </div>
</template>
<script>
import {Checkbox, CheckboxGroup, Option, OptionGroup, Select, Table, TableColumn} from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import CreditCard from "@/views/Widgets/CreditCard";
import Agreement from "./Agreement.vue";
import {Dynamic} from "../../wl";
import LoginInput from "@/views/Pages/LoginInput.vue";

export default {
    name: "register",
    components: {
        Agreement,
        CreditCard,
        [Select.name]: Select,
        [Option.name]: Option,
        [OptionGroup.name]: OptionGroup,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        LoginInput
    },

    data() {
        return {
            loading: false,
            paymentModalMessage: false,
            baseUrl: this.$baseUrl,
            agreementModal: false,
            already_member: false,
            member_id: "",
            courseFolderSelectionFocused: false,
            courseSelectionFocused: false,
            showPaymentOption: false,
            search: "",
            otherCourses: [],
            courses: [],
            course: "",
            coursefolders: [],
            checked_courses: [],
            basicPackage1: [],
            basicPackage2: [],
            checked_coursefolders: [],
            checked_other_courses: [],
            formData: {
                employee_first_name: "",
                employee_last_name: "",
                user_type: "",
                employee_address: "",
                employee_city: "",
                employee_state: "",
                employee_zipcode: "",
                employee_email: "",
                employee_phone_num: "",
                password: "",
                address: ""
            },
            employee: {
                company_name: "",
                first_name: "",
                last_name: "",
                emial: "",
                phone: "",
                address: "",
                city: "",
                state: "",
                zipcode: "",
                username: "",
                password: ""
            },
            passwordFieldType: "password",
            showPricePlan: false,
            total_cost: "",
            total_discount: "",
            discountperlocation: "",
            perYearCost: "",
            discount_msg: "",
            lead_id: "",
            siteName: "",
            infoEmail: "",
            inputStyle: "",
            enablePaymentButton: false,

            // promo code variables
            promo_code: "",
            appliedText: "",
            showPromoCodeOption: false,
            promoCodeApplied: false,
            course_cost: "",
            discounted_cost: "",
            isContinueButtonDisabled: true,
        };
    },

    mounted() {
        this.siteName = Dynamic.SITE_NAME;
        this.infoEmail = Dynamic.INFO_EMAIL;
        if (this.$route.query.inner) {
            this.$gtag.event("S.H.P. Individual signup", {method: "Google"});
        } else if (this.$route.query.course_type) {
            this.$gtag.event("Sexual Harassment Prevention Individual signup", {
                method: "Google"
            });
        } else {
            this.$gtag.event("Individual signup", {method: "Google"});
        }
    },
    async created() {
        if (this.$route.query.redirection === "yes") {
            this.employee.first_name = localStorage.getItem("fname");
            this.employee.last_name = localStorage.getItem("lname");
            this.employee.email = localStorage.getItem("email");
            this.checked_courses = JSON.parse(localStorage.getItem("courses"));
        } else {
            localStorage.removeItem("fname");
            localStorage.removeItem("lname");
            localStorage.removeItem("email");
            localStorage.removeItem("courses");
        }
        await this.$http.get("user/discountRules").then(resp => {
            for (let course of resp.data.courses) {
              console.log('getting course');
              
                if (course.course_type == 0) {
                    let obj = {
                        checked: false,
                        id: course.id,
                        course_name: course.name,
                        member_price: course.member,
                        non_member_price: course.non_member,
                        course_type: course.course_type,
                        cost: course.cost
                    };
                    this.courses.push(obj);
                } else {
                    let obj = {
                        checked: false,
                        id: course.id,
                        course_name: course.name,
                        member_price: course.member,
                        non_member_price: course.non_member,
                        course_type: course.course_type,
                        cost: course.cost
                    };
                    this.otherCourses.push(obj);
                }
            }
            this.course = this.courses;
            for (let coursefolder of resp.data.course_folders) {
              console.log('getting course folder');
              
                if (coursefolder.folder_type == 1) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        member_price: coursefolder.member,
                        non_member_price: coursefolder.non_member,
                        attachedCourses: coursefolder.active_courses
                    };

                    this.coursefolders.push(obj1);
                }
                if (coursefolder.folder_type == 2) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        member_price: coursefolder.member,
                        non_member_price: coursefolder.non_member,
                        attachedCourses: coursefolder.active_courses
                    };

                    this.basicPackage1.push(obj1);
                }
                if (coursefolder.folder_type == 3) {
                    let obj1 = {
                        id: coursefolder.id,
                        coursefolder_name: coursefolder.folder_name,
                        member_price: coursefolder.member,
                        non_member_price: coursefolder.non_member,
                        attachedCourses: coursefolder.active_courses
                    };

                    this.basicPackage2.push(obj1);
                }
            }
        });
        if (this.$route.query.course_type) {
            this.checkdefaultSexualCourse();
        }
    },
    watch:{
      checked_courses(newValue, oldValue) {
        if (this.lead_id) {
          this.lead_id= '';
          this.resetCalclatedPice();
        }
      },
      checked_coursefolders(newValue, oldValue) {
        if (this.lead_id) {
          this.lead_id= '';
          this.resetCalclatedPice();
        }
      },
    },
    methods: {
        courseFolderChecked(value) {
            if (this.checked_coursefolders.length > 0) {
                this.checked_coursefolders = [];
                this.checked_coursefolders.push(value);
                if (this.checked_coursefolders.length > 0) {
                    this.basicPackage2.forEach(chck => {
                        this.checked_other_courses.pop(chck.id);
                    });
                }
            } else {
                this.checked_coursefolders = [];
            }
        },
        checkdefaultSexualCourse() {
            this.courses.forEach(chck => {
                if (
                    chck.course_type == 0 &&
                    (chck.course_name == "Sexual Harassment Prevention (Employee)")
                ) {
                    this.checked_courses.push(chck.id);
                }

            });
        },
        finalCreateAccount() {
            this.agreementModal = false;
            this.showPaymentOption = true;
        },
        showDone() {
            this.courseSelectionFocused = true;
        },
        doneClicked() {
            this.courseSelectionFocused = false;
        },
        showDoneButton() {
            this.courseFolderSelectionFocused = true;
        },
        doneButtonClicked(e) {
            this.courseFolderSelectionFocused = false;
        },
        cancelAgreement() {
            this.agreementModal = false;
        },
        switchVisibility() {
            this.passwordFieldType =
                this.passwordFieldType === "password" ? "text" : "password";
        },
        formatPrice(value) {
            return (
                "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            );
        },
        acceptNumber() {
            var x = this.employee.phone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.employee.phone = !x[2]
                ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
        },
        showAgreement() {
            if (this.employee.password == "") {
                return Swal.fire({
                    title: "Error!",
                    text: "Please enter password to continue.",
                    icon: "error"
                });
            }
            this.formData = {
                employee_company_name: this.employee.company_name
                    ? this.employee.company_name
                    : "individual",
                employee_first_name: this.employee.first_name,
                employee_last_name: this.employee.last_name,
                user_type: "individual",
                employee_address: this.employee.address,
                employee_city: this.employee.city,
                employee_state: this.employee.state,
                employee_zipcode: this.employee.zipcode,
                employee_email: this.employee.email,
                employee_phone_num: this.employee.phone,
                password: this.employee.password,
                address: this.employee.address,
                courses: this.checked_courses,
                courseFolders: this.checked_coursefolders,
                otherCourseFolders: this.checked_other_courses,
                already_member: this.already_member,
                member_id: this.member_id,
                i_agree: true
            };
            this.agreementModal = true;
        },
        checkIfCRAMember() {
            if (!this.already_member) {
                Swal.fire({
                    title: "Already a CRA Member?",
                    html: '<a target="__blank" href="https://www.calrest.org/membership"> Click here</a> to join.',
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success btn-fill",
                    cancelButtonClass: "btn btn-danger btn-fill",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    buttonsStyling: false,
                    icon: "question"
                }).then(result => {
                    if (result.value) {
                        this.inputStyle = "border:2px solid #f8981c;";
                        this.already_member = true;

                    } else {
                        this.signupClicked();
                    }
                });
            } else {
                this.signupClicked();
            }
        },
        signupClicked() {
            if (
                this.employee.first_name &&
                this.employee.last_name &&
                this.employee.address &&
                this.employee.city &&
                this.employee.state &&
                this.employee.zipcode &&
                this.employee.email
            ) {

                this.$http
                    .post("user/lead", {
                        company_name: this.employee.company_name
                            ? this.employee.company_name
                            : "individual",
                        first_name: this.employee.first_name,
                        last_name: this.employee.last_name,
                        number_of_locations: 1,
                        number_of_employees: 1,
                        email: this.employee.email,
                        phone_num: this.employee.phone,
                        user_type: "individual",
                        already_member: this.already_member,
                        member_id: this.member_id,
                        course_ids: this.checked_courses,
                        course_folders: this.checked_coursefolders,
                        other_courses: this.checked_other_courses
                    })
                    .then(resp => {
                      this.paymentModalMessage = false;
                      this.sub_total = resp.data.sub_total;
                      this.showPricePlan = true;
                      this.lead_id = resp.data.user_id;
                      this.showPromoCodeOption = true;
                      this.isContinueButtonDisabled = false;
                        // alert(this.sub_total);
                        // this.showAgreement();
                    });
            } else {
                Swal.fire({
                    title: "Error!",
                    text: "Please fill all mandatory fields.",
                    icon: "error"
                });
            }
        },
        payClicked(cardData) {
            this.loading = true;
            this.paymentModalMessage = true;
            let payment = {
                payment_type: cardData.paymentType,
                cardholder_street_address:
                    cardData.address + "," + cardData.city + "," + cardData.state,
                cardholder_zip: cardData.zip,
                transaction_amount: "",
                token: cardData.token
            };

            if (payment.payment_type == "monthly") {
                payment.transaction_amount = this.sub_total.toFixed(2);
            }
            if (payment.payment_type == "yearly") {
                payment.transaction_amount = this.perYearCost.toFixed(2);
            }
            this.formData.payment = payment;
            this.formData.employee_address = cardData.address;
            this.formData.address = cardData.address;
            this.formData.employee_state = cardData.state;
            this.formData.employee_city = cardData.city;
            this.formData.employee_zipcode = cardData.zip;
            this.loading = false;
            this.createAccount(this.formData);
        },
        createAccount(formDataSubmitted) {
            delete this.$http.defaults.headers["authorization"];
            this.loading = true;
            this.$http
                .post("employees/register", formDataSubmitted)
                .then(resp => {
                    let ids = [];
                    let obj = {
                        id: resp.data.id
                    };
                    ids.push(obj);
                    this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                    this.paymentModalMessage = false;
                    Swal.fire({
                        title: "Success!",
                        html: `Your account has been created and is now active! <a href="https://cra.train321.com/#/login">Click here</a> to Login`,
                        icon: "success",
                        confirmButton: "btn btn-success",
                        confirmButtonText: "Ok"
                    }).then(result => {
                        if (result.value) {
                            this.$router.push("/login");
                        }
                    });
                })
                .catch((error) => {
                    this.paymentModalMessage = false;
                    this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                    return Swal.fire({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error"
                    });
                });
        },
        handleZipcodeChange(value){
          setTimeout(() => {
            const invalidFeedback = document.querySelector('span[placeholder="Zip Code"] .invalid-feedback');
            if (invalidFeedback.style.position != "relative") {
              invalidFeedback.style.position = "relative";
            }            
          }, 50);
        },
        submitLead(){
          this.resetCalclatedPice();
          this.checkIfCRAMember();
        },
        applyPromoCode() {
          this.loading = true;
          let data = {
              amount: this.sub_total,
              promocode: this.promo_code,
              leadId: this.lead_id
          }

          this.$http.post("promocode/apply_promocode", data).then(resp => {
              this.sub_total = resp.data.final_amount;
              this.course_cost = resp.data.previous_amount;
              this.discounted_cost = resp.data.discounted_amount;
              this.discount_percentage = resp.data.discount_percentage;
              this.promoCodeApplied = true;
              this.loading = false;
              this.showPromoCodeOption = false;
              // this.submitLead();
          }).catch((error) => {
              this.loading = false;
              return Swal.fire({
                  title: "Error!",
                  html: error.response.data.message,
                  icon: "error"
              });
          });
        },
        reestimate(){
          this.resetCalclatedPice();
          this.signupClicked();
        },
        resetCalclatedPice(){
          this.promoCodeApplied = false;
          this.showPromoCodeOption = false;
          this.isContinueButtonDisabled = true;
          this.sub_total = '';
          this.showPricePlan = false;
          this.promo_code = "";
        }
    },
};
</script>
<style scoped>

.payment-modal-text {
    animation: textBlink 2s linear infinite;
}
@keyframes textBlink{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}

.form-section {
  background-color: transparent;
  border-right: 1px solid #dedede;
}

hr {
  margin-top: 2px !important;
  margin-bottom: 20px !important;
}
#selected_course li {
  font-size: 0.89em;
}
#serviceAgreement {
  float: left;
  height: 300px;
  overflow: auto;
}
#serviceAgreement p {
  font-size: 0.81rem;
  text-align: justify;
}
.el-select-group__title {
  font-size: 14px !important;
  font-weight: bolder;
  color: #28c0e7;
}
.el-select-dropdown__item {
  font-size: 13px !important;
}
.reduceFont {
  font-weight: 400 !important;
}
.price-area .row {
  margin-top: 5px;
}
.bg-gradient-primary {
  background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}
.req {
  color: red;
}
.knowMore-info {
  font-size: 13px;
  margin-left: 6px;
  text-decoration: underline;
  font-style: italic;
  color: #ef8f1d !important;
  cursor: pointer;
}
.price-info {
  font-size: 13px;
  font-weight: bold;
}
.priceGrid{
  border-bottom:1px solid #dedede;
  padding-top:8px;
}
.price-info {
    display: flex;
    align-items: center;
}


.provideScroller {
  padding-top: 10px;
  overflow-y: auto !important;
  max-height: 250px !important;
}
.login-inputs {
  padding-top: 20px;
}
.ctm-btn {
  background-color: #ef8f1d;
  color: #ffffff;
  border-radius: 0 !important;
  font-size: 17px;
  margin-right: 5px;
}
.login {
  margin-right: 0px !important;
}

@media (min-width: 992px) {
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 3rem !important;
  }
}
@media (max-width: 767px) {
    .custmMob {
        margin-top: 200px;
    }
    .header-section {
        top: -285px;
    }
    .login {
        margin-right: -15px !important;
    }
}

</style>
